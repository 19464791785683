:root {
  --ck-color-focus-border: transparent !important;
  --ck-border-radius: 1rem !important;
  --ck-spacing-standard: 1rem !important;
  --ck-color-engine-placeholder-text: var(--color-text-hint) !important;
  --ck-font-size-normal: 1rem !important;
  --ck-color-list-button-hover-background: rgba(0, 0, 0, 0.04) !important;
  --ck-color-list-button-on-background: var(
    --ck-color-list-button-hover-background
  ) !important;
}

.ck-read-only {
  color: var(--color-muted)!important;
}

.ck {
  font-family: 'Inter', sans-serif !important;
}

.ck p {
  padding: var(--spacing-tight) 0 !important;
  margin: 0 !important;
  white-space: nowrap;
  overflow: hidden;
}

.page-form .ck p {
  white-space: normal;
}

.ck-body-wrapper {
  font-family: 'Inter', sans-serif !important;
}


form:hover .ck-placeholder, .ck-focused .ck-placeholder {
  visibility: visible;
}

.ck-placeholder {
  visibility: hidden;
}

.ck-placeholder,
.ck.ck-list__item {
  font-size: var(--ck-font-size-normal) !important;
}

.ck-focused {
  border: 0 !important;
  box-shadow: none !important;
}

.mention-tag-option,
.mention-ticket-option {
  font-family: var(--font-primary) !important;
  color: var(--color-text-primary) !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  width: 100% !important;
  padding: 0 var(--spacing-base-loose) !important;
  line-height: 2rem !important;
}

@media screen and (max-width: 968px) {
  .mention-tag-option,
  .mention-ticket-option {
    font-size: 0.875rem !important;
    letter-spacing: 0.00938em !important;
  }
}

.ck-content .mention,
.mention {
  background: none !important;
  color: var(--color-primary) !important;
  text-decoration: none !important;
  pointer-events: none !important;
}

.ck.ck-mentions {
  border-radius: 0 !important;
  padding: var(--spacing-tight) 0 !important;
  max-height: calc(1rem + 5 * 2rem) !important;
}

.ck.ck-list__item .ck-button:hover {
  background-color: var(--ck-color-list-button-hover-background) !important;
}

.ck.ck-list__item .ck-button.ck-on {
  color: var(--color-text-primary) !important;
}

.ck.ck-balloon-panel {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border-color: var(--color-border);
  border: none !important;
  border-radius: 4px;
}

.ck.ck-content {
  font-size: var(--ck-font-size-normal) !important;
}

.ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-balloon-panel_caret_ne.ck-balloon-panel_visible,
.ck.ck-balloon-panel.ck-balloon-panel_caret_se.ck-balloon-panel_visible {
  z-index: 3000 !important;
}

.ck.ck-editor__editable_inline {
  display: flex;
  position: relative;
  padding: 0 0 0 0.5rem !important;
}
