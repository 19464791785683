.rbc-calendar {
    font-family: 'Inter', sans-serif;
    border-radius: 0.25rem;
    transition: 200ms;
    height: 700px;
}

@media screen and (max-width: 968px) {
    .rbc-calendar {
        height: 350px;
    }

    .rbc-month-view .rbc-header {
        border-bottom: 0;
    }

    .rbc-month-view .rbc-date-cell {
        text-align: center !important;
        padding: var(--spacing-extra-tight) !important;
    }

    .rbc-month-view .rbc-event {
        border-radius: 50% !important;
        width: 10px !important;
        height: 10px !important;
        margin: 0 auto !important;
        background-color: var(--color-primary) !important;
    }

    .rbc-month-view .rbc-event.rbc-event-allday {
        border-radius: 4px !important;
        margin: 0;
        margin-bottom: 2px !important;
        width: 85% !important;
        height: auto !important;
    }

    .rbc-month-view .rbc-row-content .rbc-row {
        display: none;
    }

    .rbc-month-view .rbc-row-content .rbc-row:first-of-type,
    .rbc-month-view .rbc-row-content .rbc-row:nth-of-type(2) {
        display: flex !important;
    }
}

.rbc-current-time-indicator {
    background: var(--color-secondary) !important;
    opacity: 0.3;
}

.rbc-current-time-indicator::after {
    content: 'now';
    position: relative;
    right: calc(-100% - 0.25rem);
    top: -0.5em;
    font-size: 0.875rem;
}

.rbc-time-content > * + * > * {
    border-left: none;
}

.rbc-day-slot {
    background-color: var(--color-background);
}

.rbc-time-view {
    border: none;
}

.rbc-row-content {
    background-color: transparent;
}

.rbc-time-content {
    border-top: none;
}

.rbc-timeslot-group {
    align-items: center;
    border: none;
}

.rbc-timeslot-group:nth-child(even) {
    visibility: hidden;
}

.rbc-time-view .rbc-allday-cell {
    display: none;
}

.rbc-time-gutter .rbc-timeslot-group::before {
    content: '-';
    position: absolute;
    right: 0;
    top: 5px;
}

.rbc-time-slot:first-child {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
}

.rbc-time-column.rbc-time-gutter .rbc-timeslot-group {
    border: none;
    transform: translateY(-15px);
    position: relative;
    color: var(--color-muted);
}

.rbc-time-column.rbc-time-gutter .rbc-timeslot-group::before {
    content: '-';
    position: absolute;
    right: 0;
    color: var(--color-muted);
    top: 5px;
}

.rbc-time-gutter + .rbc-day-slot .rbc-timeslot-group {
    border-left: none;
}

.rbc-day-slot.rbc-time-column {
    margin-right: calc(2 * var(--spacing-extra-loose));
}

.rbc-time-column {
    margin-right: var(--spacing-base-tight);
}

.rbc-time-column .rbc-event {
    border-radius: 1px;
    display: block;
    border: none;
}

.rbc-time-column .rbc-event.outlook-event {
    background: #d4d4dd;
    color: gray;
}

.rbc-day-slot .rbc-event-label {
    display: none;
}

.rbc-day-slot .rbc-non-working-hour {
    opacity: 0.1;
    width: 100%;
    border: none;
}

.rbc-day-slot .rbc-events-container {
    margin-right: 0;
}

.rbc-slot-selection {
    z-index: 10;
    background-color: var(--color-primary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
}

.rbc-month-view {
    border: 0;
}

.rbc-month-view .rbc-row-content {
    background: none;
}

.rbc-month-view .rbc-row {
    cursor: pointer;
}

.rbc-month-view .rbc-day-bg {
    background: var(--color-background-paper);
    cursor: pointer;
}

.rbc-month-view .rbc-day-bg.rbc-off-range-bg {
    background: var(--color-background);
    border-color: transparent;
}

.rbc-month-view .rbc-date-cell {
    text-align: left;
    padding: var(--spacing-base-tight);
    cursor: pointer;
}

.rbc-month-view .rbc-event {
    border-radius: 4px;
    margin: 0 var(--spacing-base-tight);
    margin-bottom: 2px;
    width: 85%;
    background-color: var(--color-primary);
}

.rbc-month-view .rbc-event:focus {
    outline: none;
}

.rbc-month-view .rbc-day-bg + .rbc-day-bg {
    border: none;
}
.rbc-month-view .rbc-month-row + .rbc-month-row {
    border: none;
}

.rbc-month-view .rbc-show-more {
    display: none;
}

.rbc-month-view .rbc-header {
    text-align: left;
    padding: var(--spacing-tight) var(--spacing-base-tight);
    font-weight: normal;
    color: var(--color-muted);
}

.rbc-month-view .rbc-header + .rbc-header {
    border-left: none;
}
