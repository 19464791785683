.Toastify__toast {
    border-radius: 30px;
    background: var(--color-primary-light);
    color: var(--color-text-primary);
    padding: var(--spacing-base-loose);
    font-family: var(--font-primary);
    text-align: center;
}

.Toastify__toast-container {
    width: fit-content;
}