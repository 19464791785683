#nav-date .SingleDatePicker,
#nav-date .DateRangePicker {
    position: absolute;
    left: var(--spacing-base-tight);
    right: 0;
    text-align: center;
}

#nav-date .DateRangePicker {
    visibility: hidden;
}

#motius-nav-date {
    display: none;
}

.CalendarMonth {
    background-color: white;
}

.CalendarMonthGrid {
    background-color: white;
}

.SingleDatePicker,
.SingleDatePicker_picker {
    font-family: var(--font-primary);
    z-index: 99;
}

.SingleDatePicker_picker {
    color: var(--color-text-primary);
    background-color: white;
    border-radius: 10px;
}

.DayPicker_transitionContainer {
    background-color: white;
}

.DayPicker__horizontal {
    background-color: white;
}

#nav-date .SingleDatePickerInput {
    background: transparent;
    position: absolute;
}

.DateInput {
    width: 6.5rem;
    background: transparent;
}

.DateInput_input {
    font-weight: inherit;
    font-size: 1rem;
    padding: var(--spacing-base-tight) 0;
    width: 100%;
    line-height: inherit;
    border-bottom: none;
    font-family: var(--font-primary);
    color: var(--color-text-primary);
    font-weight: 500;
    background: transparent;
}

#motius-day-picker.DateInput_input {
    background: transparent;
}

.CalendarMonth_caption {
    color: var(--color-text-primary);
}

.CalendarMonth_caption strong {
    font-weight: 500 !important;
}

.CalendarDay__default {
    position: relative;
    border: none;
    background: transparent;
    color: var(--color-text-primary);
    z-index: 2;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:hover {
    background: var(--color-secondary) !important;
    color: white !important;
    border: none !important;
    border-radius: 0 !important;
}

.CalendarDay__selected {
    background: var(--color-primary);
}

.CalendarDay__default:hover,
.CalendarDay__selected:hover {
    background: var(--color-primary);
    border: none;
    color: white;
}

.DateRangePicker_picker {
    z-index: 9999;
}

.DateRangePicker_picker .CalendarDay__default:hover {
    background: var(--color-primary);
    border-radius: 50%;
}

.CalendarDay__selected_start {
    position: relative;
    border-radius: 20px;
    z-index: 2;
    color: white;
}

.CalendarDay__selected_start::after,
.CalendarDay__selected_end::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-color: var(--color-primary);
    z-index: -1;
}

.CalendarDay__selected_start::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    background-color: var(--color-secondary);
    z-index: -1;
}

.CalendarDay__selected_end::before {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    left: 0;
    bottom: 0;
    background-color: var(--color-secondary);
    z-index: -1;
}

.CalendarDay__selected_start.CalendarDay__selected_end::after,
.CalendarDay__selected_start.CalendarDay__selected_end::before {
    display: none;
}

.CalendarDay__selected_end {
    position: relative;
    z-index: 2;
    border-radius: 20px;
    color: white;
}

.CalendarDay__blocked_out_of_range {
    color: var(--color-disabled);
}

.DayPickerNavigation_button__verticalDefault {
    box-shadow: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 0;
}

.DayPickerNavigation_button__verticalDefault > svg {
    height: 24px;
    width: 24px;
    fill: var(--color-text-primary);
}
