@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import "./assets/fonts/fonts.css";
@import "./assets/styles/calendar.css";
@import "./assets/styles/reactDates.css";
@import "./assets/styles/toast.css";
@import "./assets/styles/ckeditor.css";

html {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  overflow: hidden;
  min-height: 100vh;
}