@font-face {
  font-family: 'TT Firs Neue';
  src: url(./TTFirsNeueMedium.woff2) format('woff2');
}

@font-face {
  font-family: 'fas-light';
  src: url(./fa-light-300.woff) format('woff'),
    url(./fa-light-300.woff2) format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'fas-solid';
  src: url(./fa-solid-900.woff) format('woff'),
    url(./fa-solid-900.woff2) format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'far-regular';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./fa-regular-400.woff2) format('woff2'),
    url(./fa-regular-400.woff) format('woff');
}

@import './fontawesome.css';
